.login-page {

  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding: 5% 0 0;
  width: 360px;

  .form{
    background: #132239; //#132239;//#c7b198;
    max-width: 360px;
    padding: 45px 45px 1px 45px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);

    #in{
      //background: #c32222;
      margin: 0 0 15px 0;
      width: 270px;
      display: inline-block;

      i {
        color: #132239; //#495057;
        background: #4CAF50;
        width: 42px;
        padding-top: 14px;
        padding-bottom: 17px;
        border: 0;
        float: left;
      }

      input {
        font-family: "Roboto", sans-serif;
        float: left;
        background: #f2f2f2;
        width: 198px;
        border: 0;
        padding: 15px;
        font-size: 14px;
      }

    }

    #wersja{
      color: white;
      font-size: 11px;
      text-align: center;
      margin-bottom: 5px;
    }

  }

  h3{
    font-family: "Comic Sans MS", "Comic Sans", serif;
    font-size: 50px;
    padding: 0;
    margin: 0;
  }

  button {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #4CAF50;
    width: 100%;
    border: 0;
    padding: 15px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background: #43A047;
    }

  }

}
