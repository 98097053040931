.stopka {
  text-align: center;
  clear: both;
  color:#FFF;
  background:#121212;
  padding: 15px 0 15px 0;
  margin:0;
  bottom: 0;
  width: 100%;
  position: fixed;

  h4{
    display: inline-block;
    padding: 0;
    margin: 0;
  }

  i{
    float: right;
    margin-right: 15px;
    //margin-top: 1px;
    font-size: 25px;

    &:hover{
      color: #3c5a86;
    }
  }

  #admin{
    float: left;
    margin-left: 15px;
  }

  #termin{
    float: right;
    margin-left: 15px;
  }
}

.main {
  text-align: center;
  margin: auto;
  margin-bottom: 75px;
  padding: 0;
  //height: 100vh;
  //alignItems: 'center';
  //justifyContent: 'center';
  //display: flex;
  //flex-direction: column;

  #info{
    margin: 20px 2px 20px 2px;
  }
}

.info{
  //float:left;
  //display: inline-block;
  //width: 200px;
  //max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 650px;
  max-width: 700px;
  min-width: 400px;
  padding: 5px;
  //margin:15px 0 5px 0;
  //border: solid #642d2d;
  //border: solid #132239;
}

.towary {
  //float:left;
  //display: inline-block;
  //width: 200px;
  //max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 650px;
  max-width: 700px;
  min-width: 400px;
  padding-bottom: 3px;
  //margin:15px 0 5px 0;
  //border: solid #642d2d;
  //border: solid #132239;
  background-color: #3c5a86;//#7f9dcb; //#c7b198;
  color: white;

  #nazwatowaru{
    background: #132239;
    color: #83C329;//wheat;
  }

  li {
    border: solid 2px #132239;
    border-radius: 5px;
    background-color: #213554; //#7f9dcb; //#3e7fe2;
    display: inline-block;
    padding: 0;
    margin: 5px;
    min-width: 60px;
    width: auto;
    cursor: pointer;

    h5{
      /*border: solid black;
      background-color: #c3124b;*/
      //display: inline-block;
      padding: 3px;
      margin: 0;
    }

    &:hover {
      background-color: #beaa44;
    }
  }

  ul {
    list-style-type: none;
    margin: 5px;
    padding: 0;
  }

  #zmianahasla{
    //display: block;
    //background: #4CAF50;
    max-width: 425px;
    text-align: right;
    margin-top: 20px;
    margin-bottom: 20px;

    #in{
      //display: inline-block;
      //float: right;
      margin: 5px;
      //padding: 20px;
    }

    input {
      font-family: "Roboto", sans-serif;
      //float: left;
      background: #f2f2f2;
      width: 198px;
      border: 0;
      padding: 10px;
      font-size: 14px;
      margin-left: 10px;
    }

  }

}

.data{
  font-family: "Roboto", sans-serif;
  //float: left;
  background: #f2f2f2;
  width: 198px;
  border: 0;
  padding: 10px;
  font-size: 14px;
  margin: 20px;
}

.kosz {
  //float:left;
  //position: center;
  //width: 73%;
  //display: inline-block;
  //alignItems: 'center';
  //justifyContent: 'center';
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 650px;
  max-width: 700px;
  //margin:25px 0 25px 15px;
  background-color: #dce9ff; //#f0ece3;
  border: solid #132239; //#642d2d;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 0;
    margin: 0;
    border-top: 1px solid #132239;

    &:hover {
      background-color: #3c5a86;
      color: white;
    }
  }

  #ulkoszyk{
    list-style-type: none;
    //margin: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 0 5px 0;
    //border-bottom: 1px solid black;
    max-width: 258px;
    //background: #4CAF50;
  }

  .srodek{
    margin-left: auto;
    margin-right: auto;
    //background: #703c86;
    max-width: 258px;

    h5{
      margin-left: auto;
      margin-right: auto;
    }
  }

  #deleteKoszyk {
    //background: #c32222;
    //display: inline-block;
    text-align: right;
    //float: right;
    //right: 0 !important;
    margin-right: 5px;
    margin-bottom: 5px;
    //min-width: 70px;
    font-size: 20px;

    &:hover{
      color: #c32222;
    }

  }

  #lielement{
    border: none;
    text-align: left;

    #nazwa{
      display: inline-block;
      min-width: 200px;
    }

    h5{
      display: inline-block;
    }
  }

  h5 {
    padding: 2px;
    margin: 0;
  }

  h2 {
    margin: 0;
    padding: 2px;
    text-align: center;
    width: auto;
    overflow: auto;
  }

  hr {
    margin: 2px 0 0 0;
    padding: 0;
  }



  .element {
    padding: 10px;

    i {
      float: left;
      font-size: 20px;
      margin-top: 7px;
      margin-left: 5px;

      &:hover{
        color: #c32222;
      }
    }

    #koszyk{
      &:hover{
        color: #83C322;
      }
    }

    button {
      float: left;
    }

    .naz{
      margin: 0;
      padding: 0;
      display: inline-block;
      font-size: small;
    }

    .censzt {
      display: inline-block;
      text-align: right;
      float: right;
      margin-right: 5px;
      min-width: 70px;
    }

  }
}

.kosznag {
  //border-bottom: solid #642d2d;
  background-color: #132239; //#c7b198;
  color: white;
  padding: 5px;

  h3 {
    margin: 0;
    padding: 0;
    display: inline-block;
  }

  i{

    display: inline-block;
    float: right;
    margin-top: 4px;
    margin-right: 4px;
    font-size: large;

    &:hover{
      color: #c32222;
    }

  }

  #dodaj:hover{
    color: #83C322;
  }
}

.select{
  color: black;
  margin: 15px;
}

.selectmain{
  max-width: 400px;
}


/*
@media (min-width: 1100px) {
  #tow {width:20%;}
  .kosz {width:73%;}
}

 */

@media (max-width: 700px){
  .towary{
    width: 97%;
    //max-width: 100%;
    margin: 20px 5px 20px 5px;
  }

  .info{
    width: auto;
    //max-width: 100%;
    //margin: 20px 15px 20px 15px;
  }

  .kosz
  {
    align-content: center;
    position: center;
    width: 97%;
    //max-width: 100%;
    margin: 20px 5px 20px 5px;
    padding: 0;
  }

  .modal {
    padding-top: 0;
  }

  .dodaj-button{
    button{
      width: 100%;
    }
  }

  .kolorbutton{
    width: 97%;
    margin-right: 5px;
    margin-left: 5px;
  }

}

.dodaj {
  //border: solid 2px #000000;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px;
  position: relative;
  overflow: hidden;


  input {
    font-family: "Roboto", sans-serif;
    outline: 0;
    background: #f2f2f2;
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 25px;
    text-align: center;
    max-width: 100px;

    &:hover {
      //border: 1px solid black;
    }
  }

  .dodaj h3 {
    background-color: #3174AD;
    color: white;
    font-family: sans-serif;
    text-align: left;
    padding: 5px;
    margin: 0;
  }

  .dodaj-main {
    margin: 15px;
  }

  .dodaj label {
    margin: 0 3px 0 5px;
  }

}

.kolorbutton{
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #83c322;
  //width: 100%;
  border: 0;
  padding: 15px;
  color: black;
  font-size: 14px;
  border-radius: 10px;
  margin: 5px;

  &:hover {
    background: #a7ea41;
  }

  i{
    margin-right: 10px;
  }
}

.dodaj-button {

  //border: solid 1px #3174AD;
  //margin-bottom: 20px;
  margin-right: 5px;
  margin-left: 5px;
  position: relative;
  overflow: hidden;

  button {
    font-family: "Roboto", sans-serif;
    //text-transform: uppercase;
    outline: 0;
    background: #83C322;
    //width: 100%;
    border: 0;
    padding: 15px;
    color: black;
    font-size: 14px;
    float: right;
    margin-bottom: 5px;
    border-radius: 10px;

    &#dodaj{
      min-width: 100px;
    }

    &#anuluj{
      background: #3e7fe2;
      float: left;

      &:hover {
        background: #d04242;
      }

    }

    &:hover {
      background: #a7ea41;
    }

    i{
      margin-right: 10px;
    }

  }
}
