.topnav {
  position: relative;
  overflow: hidden;
  background-color: #132239;
  margin-bottom: 20px;
  border: 0;
  padding: 0;

  a {
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;

    &:hover {
      background-color: #ddd;
      color: black;
    }

  }

  button.active {
    background-color: transparent;//#83C322;
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 15px 16px;
    text-decoration: none;
    font-size: 17px;
    outline: 0;
    height: 100%;
    margin: 0;
    border: 0;

    &:hover {
      background-color: #83C322;
      color: white;
    }

  }

  button.wyloguj {
    background-color: transparent;//#83C322;
    float: left;
    color: #f2f2f2;
    text-align: center;
    padding: 15px 16px;
    text-decoration: none;
    font-size: 17px;
    outline: 0;
    height: 100%;
    margin: 0;
    border: 0;

    &:hover {
      background-color: #c32222;
      color: white;
    }

  }

  .topnav-centered span {
    float: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #83C322;
    font-family: sans-serif;
    text-align: left;
  }

  .topnav-right {
    float: right;
  }

/*
  @media screen and (max-width: 600px) {
    .topnav a, .topnav-right {
      float: none;
      display: block;
    }

    .topnav-centered a {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
    }
  }

 */
}
